exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-failed-tsx": () => import("./../../../src/pages/account-failed.tsx" /* webpackChunkName: "component---src-pages-account-failed-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-appliances-tsx": () => import("./../../../src/pages/appliances.tsx" /* webpackChunkName: "component---src-pages-appliances-tsx" */),
  "component---src-pages-building-materials-tsx": () => import("./../../../src/pages/building-materials.tsx" /* webpackChunkName: "component---src-pages-building-materials-tsx" */),
  "component---src-pages-electrical-tsx": () => import("./../../../src/pages/electrical.tsx" /* webpackChunkName: "component---src-pages-electrical-tsx" */),
  "component---src-pages-finishing-tsx": () => import("./../../../src/pages/finishing.tsx" /* webpackChunkName: "component---src-pages-finishing-tsx" */),
  "component---src-pages-flooring-tsx": () => import("./../../../src/pages/flooring.tsx" /* webpackChunkName: "component---src-pages-flooring-tsx" */),
  "component---src-pages-hvac-tsx": () => import("./../../../src/pages/hvac.tsx" /* webpackChunkName: "component---src-pages-hvac-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plumbing-tsx": () => import("./../../../src/pages/plumbing.tsx" /* webpackChunkName: "component---src-pages-plumbing-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */)
}

